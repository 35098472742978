import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Slide from "../../utility/slide_content"
import CarouselComponent from "../carousel-component"
import "./ColorTabToggler.scss"

const ColorTabToggler = props => {
  const { title, images } = props

  const darkColors = ["#FE7D7D", "#D67FBF", "#8E97E1", "#B0E9A4"]
  const lightColors = ["#FFC3C3", "#FFD1F3", "#D4D9FF", "#e0ffd9"]

  const [activeName, setActiveName] = useState()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    setActiveName(title[0])
  }, [])

  const handleTabChange = (name, i) => {
    setActiveName(name)
    setActiveIndex(i)
  }

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const FadeChildren = props => {
    return (
      <Fade
        delay={props.delay ? props.delay : 300}
        duration={props.duration ? props.duration : 500}
        bottom
        distance={props.distance ? props.distance : "30px"}
      >
        {props.children}
      </Fade>
    )
  }

  return (
    <div className="color-toggler">
      <div className="d-mob mb16">
        <CarouselComponent
          swipeable
          customButtonNew
          status
          items={1}
          paginationDecrement={screenWidth >= 992 ? true : false}
          arrowClassName="btn-secondary-new"
        >
          {title?.map((item, idx) => {
            return (
              <div
                className={`individual-part d-flex flex-column gap-24 align-items-center`}
                css={css`
                  background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 27.47%,
                    ${lightColors[activeIndex]} 50.63%,
                    rgba(255, 255, 255, 0) 83.38%
                  );
                `}
              >
                <p
                  className="mb16 fw-bold tab"
                  css={css`
                    background: ${darkColors?.[idx]};
                  `}
                >
                  {item}
                </p>
                <div>
                  <img src={images?.[idx]?.url} className="w-100" />
                </div>
              </div>
            )
          })}
        </CarouselComponent>
      </div>

      <div className="d-dsk">
        <div
          css={css`
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 27.47%,
              ${lightColors[activeIndex]} 50.63%,
              rgba(255, 255, 255, 0) 83.38%
            );
          `}
        >
          <div className="Layout-container-big mx-auto">
            <Slide delay="200">
              <Col
                lg={10}
                id="floating_tabs"
                className={`floating-tabs mx-auto d-dsk d-flex justify-content-between br10`}
              >
                {title.map((name, i) => {
                  return (
                    <button
                      className={`resource-button br10 z-1 alt ${name ===
                        activeName && "active"}`}
                      onClick={() => handleTabChange(name, i)}
                      role="button"
                      tabIndex={0}
                      css={css`
                        background: linear-gradient(
                          90deg,
                          ${darkColors[i]} -60%,
                          rgba(255, 255, 255, 0) 80%
                        );
                        &.active {
                          background: linear-gradient(
                            90deg,
                            ${darkColors[i]} 0%,
                            ${lightColors[i]} 103.13%
                          );
                        }
                      `}
                    >
                      <div className="button-name d-flex align-items-center justify-content-between fw-bold">
                        {name.toUpperCase()}
                        {/* <SVGIcon
                      name="home/rightArrBlack"
                      className="right-arrow-black"
                    /> */}
                      </div>
                    </button>
                  )
                })}
              </Col>
            </Slide>

            <Col lg={12} className="mx-auto">
              <Slide delay="200">
                <Fade delay={10} duration="1000">
                  <div className={`prelative parent-img-wrapper mx-auto`}>
                    <div className="img-wrapper">
                      <FadeChildren
                        distance="0"
                        delay={150}
                        className="prelative"
                      >
                        <img
                          src={images?.[activeIndex]?.url}
                          className="image w-100"
                          alt="image"
                        />
                      </FadeChildren>
                    </div>
                  </div>
                </Fade>
              </Slide>
            </Col>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ColorTabToggler
